import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ConsultarClientes.module.css';
import { Search,PinMap,BusFrontFill,DatabaseFillCheck, Calendar2CheckFill,CurrencyExchange,Upc  } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';



class ConsultarClientes extends Component {
  // state = {
  //   data: [],
  // };
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      formData: {
        lugar: '',
        destino: '',
        valor: '',
        fecha: '',
        cupo: 0 ,
        origen:'',
        codigo:'',
      },
      isSelected: false,
      seleccionado:null,
      dataDos:[], 
    };

    this.stateData = {
      formData: [
        {
          lugar: '',
          destino: '',
          valor: '',
          fecha: '',
          cupo: 0 ,
        },
      ],
      isSelected: false,
      
    };
  }
 
  componentDidMount() {
    const url_local= process.env.REACT_APP_API_SERVER_LOCAL_OFERTS
    const url_external = process.env.REACT_APP_API_SERVER_EXTERNAL
    // Realizar una solicitud GET al servidor PHP
    // 'https://apirestfull.zukytech.com/index.php?req=ALL_OFERTAS'
    axios.get(`${url_external}?req=ALL_OFERTAS`)
      .then((response) => {
        console.log('data ofertas',response.data);
        this.setState({ data: response.data });
        
        
      })
      .catch((error) => {
        console.error('Error al obtener datos: ' + error.message);
      });


      axios.get(`${url_external}?req=ALL_SERVICES`)
      .then((response) => {
        console.log('data servicios',response.data);
        this.setState({ dataDos: response.data });
        
      })
      .catch((error) => {
        console.error('Error al obtener datos: ' + error.message);
      });

  }


  // handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   this.stateData({ [name]: value });
  // };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,

      },
    }));
  };


  handleSelect = (index) => {
    

    this.setState({ 
      isSelected: true
     
    });
    

    this.setState({ 
      seleccionado: index
     
    });

    alert('A seleccionado la oferta, presione tomar');
    console.log(true);
   


  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.isSelected) {
      // Aquí puedes manejar el envío del formulario para este componente específico
      console.log('Lugar: ',e.target.elements.lugar.value);
      console.log('Valor: ',e.target.elements.valor.value);
      console.log('Destino: ',e.target.elements.destino.value);
      console.log('Origen: ',e.target.elements.origen.value);
      console.log('Cupo: ',e.target.elements.cupo.value);
      console.log('Fecha: ',e.target.elements.fecha.value);
      console.log('Email: ',localStorage.getItem('email'));


      const dataform = new  FormData();
    
      dataform.append('data','create_servicios');
      dataform.append('code_oferta',e.target.elements.codigo.value);
       dataform.append('nombre_servicios',localStorage.getItem('email'));
       //dataform.append('fecha',);
       dataform.append('cupos_servicios',e.target.elements.cupo.value);
       dataform.append('origen_servicios',e.target.elements.origen.value);
       dataform.append('destino_servicios',e.target.elements.destino.value);

      
      const url_local= process.env.REACT_APP_API_SERVER_LOCAL_OFERTS
      const url_external = process.env.REACT_APP_API_SERVER_EXTERNAL
     
      
         axios.post(`${url_external}`, dataform)
           .then(res => {
            console.log(res);
             if(res.data.codigo == 'ok'){
                       alert("Realizado");
              //  localStorage.setItem('datajwt',res.data.jwt.jwt_user);
              //  localStorage.setItem('email',dataPost.email);
              //  localStorage.setItem('tipo',res.data.jwt.tipo);
              
              //  navigate('/'); // Redirige a la página de inicio
  
             }else{
               console.log('no funciona');
                     
            }
  
        //     // Puedes manejar la respuesta del servidor aquí
           })
          .catch(error => {
             console.error('Error en la solicitud:', error);
           });


       



    }
  };

 

  handleSelectChange = (e) => {
    this.setState({ selectedOption: e.target.value });
  };
  
  // Otras funciones para manejar solicitudes POST, PUT, DELETE, etc.
  
  render() {
    const { seleccionado } = this.state;
    return (
      <div className={styles.contenedor}>
     
      <div className={styles.divOfertas}>  
       {/* <h5>OFERTAS ACTIVAS</h5>  */}
      <table className={styles.tablaOfertas}>
        <thead>
          <tr>
            <th className="cabecera"><Upc size={36} color="green" />codigo</th>
            <th className="cabecera"><PinMap size={36} color="green" />lugar</th>
            <th className="cabecera"><CurrencyExchange size={36} color="green" />valor</th>
            <th className="cabecera"><Calendar2CheckFill size={36} color="green" />fecha</th>
            <th className="cabecera"><PinMap size={36} color="green" />destino</th>
            <th className="cabecera"><PinMap size={36} color="green" />origen</th>
            <th className="cabecera"><BusFrontFill size={36} color="green" />cupos</th>
            <th className="cabecera"><BusFrontFill size={36} color="green" />transporte</th>
            <th className="cabecera"><DatabaseFillCheck size={36} color="green" />estado</th>
            <th className="cabecera">selector</th>
            <th className="cabecera">selector cupos</th>
            {/* Agrega otras columnas según tus datos */}
          </tr>
        </thead>
        <tbody>
         
          {
         
          this.state.data.map((item, index) => (
           
            <tr  key={item.codigo} className={seleccionado === index ? styles.resaltado : ''} >
              <td>{item.codigo}</td>
              <td>{item.lugar}</td>
              <td>{item.valor}</td>
              <td>{item.fecha}</td>
              <td>{item.destino}</td>
              <td>{item.origen}</td>
              <td>{item.cupo}</td>
              <td>{item.tipoTransporte}</td>
              <td>{item.estado}</td>
              <td><button onClick={()=>this.handleSelect(index)} className={styles.boton_redondeado} >SELECCIONAR</button> </td>
              <td>
              
              <form onSubmit={this.handleSubmit}>
        <div>
          
          <input
            type="hidden"
            id="lugar"
            name="lugar"
            value={item.codigo}
            onChange={this.handleInputChange}
          />
        </div>
        <div>
          
          <input
            type="hidden"
            id="codigo"
            name="codigo"
            value={item.codigo}
            onChange={this.handleInputChange}
          />
        </div>
        <div>
         
          <input
            type="hidden"
            id="destino"
            name="destino"
            value={item.destino}
            onChange={this.handleInputChange}
          />
        </div>

        <div>
          
        <input
            type="hidden"
            id="valor"
            name="valor"
            value={item.valor}
            onChange={this.handleInputChange}
          />
        </div>
        <div>
          
        <input
            type="hidden"
            id="fecha"
            name="fecha"
            value={item.fecha}
            onChange={this.handleInputChange}
          />
        </div>
        <div>
          
          <input
              type="number"
              id="cupo"
              name="cupo"
              // value={item.cupo}
              className={styles.cupo}
              onChange={this.handleInputChange}
            />
          </div>
          <div>
          
          <input
              type="hidden"
              id="origen"
              name="origen"
               value={item.origen}
              className={styles.cupo}
              onChange={this.handleInputChange}
            />
          </div>
        <button type="submit">TOMAR CUPO</button>
      </form>
              </td>
              {/* Renderiza otras columnas aquí */}
            </tr>
          ))
          
          }
       
        </tbody>
      </table>
      </div>

     

    </div>
      
      
    );
  }
    
}

export default ConsultarClientes;
