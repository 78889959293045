import styles from "./navigation.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import IconLogo from '../svgIcons/iconLogo'
import {deleteStorageObject} from "../../utils/storage"
import React, { useEffect, useRef, useState } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import {HiMagnifyingGlass, HiOutlinePlusCircle} from 'react-icons/hi2'
import { getStorageObject } from "../../utils/storage";
import UserAvatar from "../userAvatar/UserAvatar";
import { Request } from "../../utils/apiWrapper";
import { ChatList } from "../chat/chatList";
import Menu from '../menu/menuComponent ';
import Conductor from '../menu/menuComponentConductor';
import Tarifa from '../menu/menuComponentTarifa';

const Navigation = ({setOpenModal, setWhatModal , refresh , setRefresh} ) => {
const navigate=useNavigate()
let tokenRef=useRef()
let userNameRef=useRef()
let userPicRef=useRef()
const [token,setToken]=useState("")
const [ID,setID]=useState("")
const [notifications,setnotifications] = useState(0)
const [hasNotifications,setHasNotifications]=useState(false)

const getNotifications = async() =>
    {
        const session = getStorageObject("user-session")
        const response = await Request(`/notification/${session.userObj.userID}`)
        const numberOfNotifications = response.filter(notification => notification.read === false).length
        

        if (numberOfNotifications!==0){
        setHasNotifications(true)
        setnotifications(numberOfNotifications)
        }
    }
useEffect(()=>{
  if(!token){ 
  const sessiontoken = getStorageObject("user-session")
  if (sessiontoken){
  tokenRef.current = sessiontoken.jwtToken
  userNameRef.current = sessiontoken.userObj.userName
  userPicRef.current = sessiontoken.userObj.picUrl
  setToken(sessiontoken.jwtToken)
  setID(sessiontoken.userObj.userID)
  getNotifications()
  }
  }
  
})




console.log({hasNotifications})




   const logOut = () =>{
   
    localStorage.clear();
    navigate('/');
   }

const jwt = localStorage.getItem('datajwt');
if(!jwt){
 return(
  <Navbar bg="white" expand="lg" id={styles.navbar}>
    <Container>
      <Navbar.Brand href="/">
       
       <h2>COMPARTE TU VIAJE</h2>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="justify-content-end">{/* <Nav.Link href="/search"><HiMagnifyingGlass size={38} className={` mr-auto ${styles.glass}`}/></Nav.Link> */} {/* {tokenRef.current&&<Nav.Link onClick={()=>{navigate("/trips");setOpenModal(true); setWhatModal("NewTrip")}}><HiOutlinePlusCircle size={38} className={` mr-auto ${styles.glass}`}/></Nav.Link>} */} 
        <NavDropdown id="basic-nav-dropdown" 
        title={ 
        
        <div className={styles.wrapperUserAvatar}> 
        <UserAvatar hasNotifications={hasNotifications} localization="navBar" user={userNameRef.current} picUrl={userPicRef.current} className="mr-auto"/> 
        </div> 
           
           }> 
        {
        
            !tokenRef.current?
                          (<>
                         <NavDropdown.Item onClick={()=>{
                           navigate("/login");setOpenModal(true); setWhatModal("Login")}} >
                           Inicia Sesión
                         </NavDropdown.Item> 
                            
                        <NavDropdown.Divider /> 
                            
                        <NavDropdown.Item onClick={()=>{
                          navigate("/users");setOpenModal(true); 
                          setWhatModal("NewUser")}}>
                            Regístrate
                        </NavDropdown.Item>
                          </>) 
                          : 
                          (<> 
                           <Menu /> 
                           <Conductor /> 
                           <Tarifa />                          
                            <NavDropdown.Item onClick={logOut} style={{ background:'red' }}>
                              Cerrar Sesión
                            </NavDropdown.Item> 
                              </>)
          
          }
        </NavDropdown>
        </Nav> 
        </Navbar.Collapse>
       
    </Container>
    <div>
    {tokenRef.current && <ChatList/>}
    </div>
  </Navbar>    
  
)//fin return 
}else if(jwt && (localStorage.getItem('tipo')==='admin')){

  return(
    <Navbar bg="white" expand="lg" id={styles.navbar}>
    <Container>
      <Navbar.Brand href="/">
        {/* <IconLogo className={styles.logoSvg}/> */}
       COMPARTE TU VIAJE (ADMINISTRACION)
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <h5>{localStorage.getItem("email")}</h5>
        <Nav className="justify-content-end">
        {/* <Nav.Link href="/search"><HiMagnifyingGlass size={38} className={` mr-auto ${styles.glass}`}/></Nav.Link> */}
        {tokenRef.current&&<Nav.Link onClick={()=>{navigate("/trips");setOpenModal(true); setWhatModal("NewTrip")}}><HiOutlinePlusCircle size={38} className={` mr-auto ${styles.glass}`}/></Nav.Link>}
        <NavDropdown id="basic-nav-dropdown" title={<div className={styles.wrapperUserAvatar}><UserAvatar hasNotifications={hasNotifications} localization="navBar" user={userNameRef.current} picUrl={userPicRef.current} className="mr-auto"/></div>}>
          
               {
               tokenRef.current?
            (<>
            <NavDropdown.Item onClick={()=>{navigate("/login");setOpenModal(true); setWhatModal("Login")}} >
              Inicia Sesión
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={()=>{navigate("/users");setOpenModal(true); setWhatModal("NewUser")}}>
                Regístrate
             </NavDropdown.Item>
            </>)
            
            :
            (<>
                 
            <Menu /> 
            <Conductor /> 
            <Tarifa />
            <NavDropdown.Item onClick={logOut} style={{ background:'red' }}>
              Cerrar Sesión
            </NavDropdown.Item>
            </>)
          }
        </NavDropdown>
        </Nav> 
        </Navbar.Collapse>
        
    </Container>
    <div>
    {tokenRef.current && <ChatList/>}
    </div>
  </Navbar>    
    
  )//fin return 

}else if(jwt && (localStorage.getItem('tipo')==='cliente')){
  return(
    <Navbar bg="white" expand="lg" id={styles.navbar}>
    <Container>
      <Navbar.Brand href="/">
        {/* <IconLogo className={styles.logoSvg}/> */}
       COMPARTE TU VIAJE (CLIENTE)
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <h5>{localStorage.getItem("email")}</h5>
        <Nav className="justify-content-end">
        {/* <Nav.Link href="/search"><HiMagnifyingGlass size={38} className={` mr-auto ${styles.glass}`}/></Nav.Link> */}
        {tokenRef.current&&<Nav.Link onClick={()=>{navigate("/trips");setOpenModal(true); setWhatModal("NewTrip")}}><HiOutlinePlusCircle size={38} className={` mr-auto ${styles.glass}`}/></Nav.Link>}
        <NavDropdown id="basic-nav-dropdown" title={<div className={styles.wrapperUserAvatar}><UserAvatar hasNotifications={hasNotifications} localization="navBar" user={userNameRef.current} picUrl={userPicRef.current} className="mr-auto"/></div>}>
          
               {
               tokenRef.current?
            (<>
            <NavDropdown.Item onClick={()=>{navigate("/login");setOpenModal(true); setWhatModal("Login")}} >
              Inicia Sesión
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={()=>{navigate("/users");setOpenModal(true); setWhatModal("NewUser")}}>
                Regístrate
             </NavDropdown.Item>
            </>)
            
            :
            (<>
                 
            <Menu /> 
            <Conductor /> 
            <Tarifa />
            <NavDropdown.Item onClick={logOut} style={{ background:'red' }}>
              Cerrar Sesión
            </NavDropdown.Item>
            </>)
          }
        </NavDropdown>
        </Nav> 
        </Navbar.Collapse>
        
    </Container>
    <div>
    {tokenRef.current && <ChatList/>}
    </div>
  </Navbar>    
    
  )//fin return 

}

}

export default Navigation