import React from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoBackground.module.css';


const VideoBackground = () => {
  return (
    <div className={styles.video_background}>
      <video  autoPlay muted loop>
      {/* https://vod-progressive.akamaized.net/exp=1699143463~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F3500%2F7%2F192501931%2F641843293.mp4~hmac=62cc2f50b25613c50739d3dc303dcf0ad0bdaa3f3beb3b9eeaf622f825f6bdf4/vimeo-prod-skyfire-std-us/01/3500/7/192501931/641843293.mp4?filename=file.mp4 */}
         <source src="https://acortar.link/gR0NtB" type="video/mp4" /> 
        <source src="https://compartetuviaje.zukytech.com/traffic.mp4" type="video/mp4" />
        Tu navegador no soporta videos en HTML5.
      </video>
      {/* Agrega aquí el contenido de tu página */}
    </div>
  );
};

export default VideoBackground;