import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';



function AutocompletarComponent() {

    const mapContainer = useRef(null);
    
    useEffect(() => {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: [-74.5, 40],
          zoom: 9,
        });
    
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
        });
    
        map.addControl(geocoder);
    
        return () => {
          map.remove();
        };
      }, []);
   
    

  return <div id="map" style={{ height: '400px' }} />;
}

export default AutocompletarComponent;
