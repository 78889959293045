import styles from './login.module.css'
import React, { useState} from "react";
import { redirect, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ConfigIcon from "../IconConfig/iconsize_small";
import { AiOutlineEye} from "react-icons/ai"
import { Request } from "../../utils/apiWrapper";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { setStorageObject } from "../../utils/storage";

 export const Login = (props) =>{
  const [dataPost, setData] = useState({
    data: 'auth_user_axios',
    email: '',
    pass: '123',
    // Agrega otros campos según tus necesidades
  });
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const redirigirAlInicio = () => {
      useNavigate.push('/'); // Redirige a la página de inicio
    };
    const dataform = new  FormData();
    
    dataform.append('data',dataPost.data);
    dataform.append('email_user',dataPost.email);
    dataform.append('password_user',dataPost.pass);
    
    const url_local= process.env.REACT_APP_API_SERVER_LOCAL_OFERTS
    const url_external = process.env.REACT_APP_API_SERVER_EXTERNAL
   
    
      axios.post(`${url_external}`, dataform)
        .then(res => {
         console.log(res);
          if(res.data.codigo == 'ok'){
                     
            localStorage.setItem('datajwt',res.data.jwt.jwt_user);
            localStorage.setItem('email',dataPost.email);
            localStorage.setItem('tipo',res.data.jwt.tipo);
            
            navigate('/'); // Redirige a la página de inicio

          }else{
            console.log('no funciona');
                   
          }

          // Puedes manejar la respuesta del servidor aquí
        })
       .catch(error => {
          console.error('Error en la solicitud:', error);
        });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  
 
    return(
      <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="key1">Usuario:</label>
          <input
            type="text"
            name="email"
            value={dataPost.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="key2">Contraseña:</label>
          <input
            type="password"
            name="pass"
            value={dataPost.pass}
            onChange={handleChange}
          />
        </div>
        {/* Agrega más campos según tus necesidades */}
        <button type="submit" >Enviar</button>
      </form>
    </div>
    
    )
  

}

