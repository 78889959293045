import React from 'react';
import styles from './VideoComponent.module.css';

class VideoComponent extends React.Component {

    componentDidMount() {
        const videoElement = document.getElementById('video'); // Obtén el elemento de video por su ID
        const videoUrl = '../../../src/traffic.mp4'; // Reemplaza 'URL_DEL_VIDEO' con la URL del video externo
      
        videoElement.crossOrigin = 'anonymous'; // Habilita CORS para el elemento de video
      
        videoElement.src = videoUrl; // Establece la URL del video
      }

  render() {
    return (
      <div className={styles.video_background}>
        <video id="video" controls width="640" height="360">
          Tu navegador no admite la etiqueta de video.
        </video>
      </div>
    );
  }


  
}

export default VideoComponent;
