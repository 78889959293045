
import React, { useEffect, useRef, useState } from 'react';
import styles from './MapComponent.module.css';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
// import MapboxDirections from '@mapbox/mapbox-gl-directions';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
// import MapboxDirections from '@mapbox/mapbox-gl-directions';

import iconoSearch from '../components/assets/iconosearch.svg';
import data from 'mapbox-gl-directions/src/reducers';
import axios from 'axios';

mapboxgl.accessToken = 'pk.eyJ1IjoiY3Jpc3RpYW4zY2IiLCJhIjoiY2xuNnR1MmIwMGgyMDJtbzFrNGE1aGp4aCJ9.zMxwQi0eJGfGm1dBzaZ7MA';

const Map=()=>{
  const [mensaje, setMensaje] = useState('');
  const mapContainer = useRef(null);
  const originInput = useRef(null);
  const destinationInput = useRef(null);
  const [dataPost, setData] = useState({
    data: 'create_oferta',
    valor: '',
    cupos: '',
    lugar:''
     // Agrega otros campos según tus necesidades
  }); 



  useEffect(() => {
    // Crea un nuevo mapa solo una vez cuando el componente se monta
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [-74.5, 40],
      zoom: 1,
    });
    
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });




    // map.addControl(geocoder);


    const geocoderOrigin = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: 'ORIGEN', // Etiqueta del campo de origen
      marker: {
        color: '#0080ff', // Color del marcador
        content: iconoSearch, // Reemplaza con el ícono personalizado
      },
    
    });

    const geocoderDestination = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: 'DESTINO', // Etiqueta del campo de destino
      marker: {
        color: '#ff0000', // Color del marcador
        content: iconoSearch, // Reemplaza con el ícono personalizado
      },
    });


    map.addControl(geocoderOrigin,'top-left');
    map.addControl(geocoderDestination);


    
    // Coordenadas de los marcadores (reemplaza con las coordenadas correctas)
var origenCoords = null;
var destinoCoords = null;


    // Manejar eventos del Geocoder para el origen
    geocoderOrigin.on('result', (e) => {
      if (e.result.text) {
        originInput.current.value = e.result.text +":"+ e.result.geometry.coordinates;
        origenCoords = e.result.geometry.coordinates;
      }
      
    });

    

    // Manejar eventos del Geocoder para el destino
    geocoderDestination.on('result', (e) => {
      if (e.result.text) {
        destinationInput.current.value = e.result.text +":"+ e.result.geometry.coordinates;
        destinoCoords =  e.result.geometry.coordinates;
      }
      
    });

   
   
    document.getElementById('trazarRuta').addEventListener('click', () => {
      if (origenCoords && destinoCoords) {
        // Crear un objeto GeoJSON para la línea de ruta
        const geojson = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [origenCoords, destinoCoords],
          },
        };
    
        
    

        map.addSource('ruta', {
             type: 'geojson',
             data: {
              type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [origenCoords, destinoCoords],
      },
             },
           });

        map.addLayer({
          id: 'ruta',
          type: 'line',
          source: 'ruta',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#0080ff',
            'line-width': 3,
          },
        });
      }
    });

    // Limpia el mapa cuando el componente se desmonta
    return () => {
      map.remove();
    };
  }, []);

  const  boton=styles.btnTransporte; 
  const  centrar = styles.centrar_texto; 

  const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
  
  const handleChangeDos = (event) => {
    setOpcionSeleccionada(event.target.value);
  };

  const url_local= process.env.REACT_APP_API_SERVER_LOCAL_OFERTS
  const url_external = process.env.REACT_APP_API_SERVER_EXTERNAL


  const handleSubmit = async (e) => {
    e.preventDefault();
       console.log('Origen: ', originInput.current.value);
       console.log('Destino: ', destinationInput.current.value);
       console.log('data: ',dataPost.cupos);
       console.log('data: ',dataPost.data);
       console.log('data: ',dataPost.valor);
       console.log('auto seleccionado: ', opcionSeleccionada);

       const dataform = new  FormData();
    
       dataform.append('data',dataPost.data);
       dataform.append('oferta_lugar',dataPost.lugar);
       dataform.append('oferta_destino',destinationInput.current.value);
       dataform.append('oferta_valor',dataPost.valor);
       dataform.append('oferta_cupos',dataPost.cupos);
       dataform.append('oferta_transporte',opcionSeleccionada);
       dataform.append('oferta_estado','activa');
       dataform.append('oferta_origen',originInput.current.value);
       
       const url_local= process.env.REACT_APP_API_SERVER_LOCAL_OFERTS
       const url_external = process.env.REACT_APP_API_SERVER_EXTERNAL
      
       
         axios.post(`${url_external}`, dataform)
           .then(res => {
            console.log(res);
             if(res.data.codigo == 'ok'){
                        
              setMensaje('Oferta creada');
               
              //  navigate('/'); // Redirige a la página de inicio
   
             }else{
               console.log('no funciona');
               setMensaje('Oferta no creada');
                      
             }
   
             // Puedes manejar la respuesta del servidor aquí
           })
          .catch(error => {
             console.error('Error en la solicitud:', error);
           });



     }


  const handleChange = (e) => {
    setData({
      ...dataPost,
      [e.target.name]: e.target.value,
    });
  }


return(
  <div>
  <div className={styles.buscarViajeMap}  ref={mapContainer} />
  
  <div >
        <div >
          
          <div id="geocoder-origin" />
          
          {/* <label for="geocoder-origin" class="label">Origen:</label>  */}
          <input
            type="text"
            placeholder="VISUALIZAR ORIGEN"
            name="origen"
            ref={originInput}
            className={styles.origenInput}
            // value={dataPost.origen}
            //  onChange={handleChange}
            readOnly  />
         
        </div>
        <div  >
          
          <div id="geocoder-destination" />
          <div >
          {/* <label for="geocoder-destination" >Destino:</label>   */}
          <input
            type="text"
            placeholder="VISUALIZAR DESTINO"
            name="destino"
            ref={destinationInput}
            className={styles.destinoInput}
            // value={dataPost.destino}
          //  onChange={handleChange}
           readOnly />
          </div>
      <label className={styles.centrar_texto}>INDICA DATOS DE LA OFERTA</label>   
  <div >
         
          <label className={styles.camposEditables}>
           VALOR
          <input
            type="number"
            placeholder="$ VALOR"
            name="valor"
            className={styles.camposEditables}
            value={dataPost.valor}
            onChange={handleChange}
           />
          </label>
          </div>
          <div >
         <label className={styles.camposEditables}>
          CUPOS
         <input
           type="number"
           placeholder="cupos"
           name="cupos"
           className={styles.camposEditables}
           value={dataPost.cupos}
            onChange={handleChange}
          />
         </label>
         </div>
         <div >
         <label className={styles.camposEditables}>
          LUGAR
         <input
           type="text"
           placeholder="lugar"
           name="lugar"
           className={styles.camposEditables}
           value={dataPost.lugar}
            onChange={handleChange}
          />
         </label>
         </div>
         <label  className={styles.centrar_texto}>INDICA EL TIPO DE TRANSPORTE</label>
         <div>      
      <select id="opciones" value={opcionSeleccionada} onChange={handleChangeDos} className={styles.camposEditables}>
        <option value="">...</option>
        <option value="camioneta">🚙 camioneta suv</option>
        <option value="sedan">🚗 sedan</option>
        <option value="van">🚐 van</option>
        <option value="otro">otro</option>
      </select>

      <p className={styles.camposEditables}>Cupos registrados: {opcionSeleccionada}</p>
    </div>
        </div>
        
      </div>
      <br/>
      <br/>
      <button  id="trazarRuta" className={`${boton} ${centrar}`} onClick={handleSubmit}  >LANZAR OFERTA</button>
      {mensaje && <p className={styles.centrar_texto} >{mensaje}</p>}
  </div>
  
)


}

export default Map


