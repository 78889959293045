import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import styles from '../useProfileUser/useProfileUser.module.css';

const UseProfileUser = ({ isOpen, onClose }) => {

  const [datos, setDatos] = useState([]);
  const [perfil, setPerfil] = useState({
    email: '',
    nombres: '',
    avatar: '',
    // Agrega más campos según sea necesario
  });

  useEffect(() => {

    const url_local= process.env.REACT_APP_API_SERVER_LOCAL_OFERTS
    const url_external = process.env.REACT_APP_API_SERVER_EXTERNAL
    
    // Función para realizar la solicitud GET
    const fetchData = async () => { 
      try {
        // Realizar la solicitud GET a la URL deseada
        const response = await axios.get(`${url_external}?req=ALL_USERS`);
         
        // Actualizar el estado con los datos recibidos
        // setDatos(response.data);
//         if(localStorage.getItem('email')){
// console.log('correo',localStorage.getItem('email'));
//         }
        const usuarioEspecifico = response.data.find((usuario) => usuario.email === localStorage.getItem('email'));
        
        console.log('Usuario especifico ',usuarioEspecifico);
        setPerfil(usuarioEspecifico);
      } catch (error) {
        console.error('Error al realizar la solicitud GET:', error);
      }
    };

    // Llamar a la función para realizar la solicitud al montar el componente
    fetchData();
  }, []); // El segundo argumento [] asegura que useEffect se ejecute solo al montar el componente


// Manejador de cambios para actualizar el estado cuando los campos del formulario cambian
const handleChange = (event) => {
  const { name, value } = event.target;
  setPerfil({ ...perfil, [name]: value });
};

// Manejador de envío para procesar el formulario
const handleSubmit = (event) => {
  event.preventDefault();
  // Aquí puedes realizar acciones como enviar los datos a un servidor o almacenarlos localmente
  console.log('Perfil creado:', perfil);
};

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Ejemplo de Modal"
    >
      <div className={styles.contenedor}>
      <button onClick={onClose} className={styles.close}>CERRAR</button>
      <h1>Perfil</h1>      
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input
            type="text"
            name="email"
            value={perfil.email}
            onChange={handleChange}
          />
        </label>
        <br />

        <label>
          Nombre:
          <input
            type="text"
            name="nombre"
            value={perfil.nombres}
            onChange={handleChange}
          />
        </label>
        <br />

        {/* <label>
          Correo:
          <input
            type="email"
            name="correo"
            value={perfil.avatar}
            onChange={handleChange}
          />
        </label> */}
        <br />

        {/* Agrega más campos según sea necesario */}

        {/* <button type="submit">Crear Perfil</button> */}
      </form>
    </div>

     
      
    </Modal>
  );
};

export default UseProfileUser;